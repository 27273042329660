<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        md="12"
        class="pb-12"
      >
        <div class="page-title text-center pa-5 ">
          <h1
            class="text-3xl font-weight-semibold text--primary d-flex align-center justify-center"
          >
            <span class="me-2">Welcome to FantasyKombat!</span>
          </h1>

          <p class="mt-2 mb-6">
            As part of the early Beta cohort you're getting some juicy rewards.
            <br>
            <strong>{{ amount|toCurrencyNoDec }} KombatCoins</strong> to help you get started, plus
            a free fighter pack.
          </p>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="12"
      >
        <div
          class="cards-wrapper"
          :class="{ 'closed': !startClaim, 'opened': startClaim, 'mobile': isMobile}"
          @click="openPack"
        >
          <div class="cards-box">
            <v-row>
              <v-col
                cols="12"
                md="12"
                class="pb-12"
              >
                <div class="card team">
                  <div
                    class="ml-7 mt-3 v-card v-sheet v-sheet--outlined theme--dark px-5  py-4 mr-8 glow glow-primary"
                    :class="{ 'active': !fighterClaimed }"
                  >
                    <h2 class="text-center">
                      Create Your Team
                    </h2>
                    <p class="mt-9 opacity-1">
                      With money 💰 in the bank and your first fighter 👊 recruited it's time to
                      setup your
                      FantasyKombat team.
                    </p>
                    <v-flex class="text-xs-center align-center ml-12">
                      <a href="/edit-team">
                        <v-btn
                          class="ml-2 mt-8 ml-8"
                          color="primary"
                        >
                          Create Team
                        </v-btn>
                      </a>
                    </v-flex>
                  </div>
                </div>
              </v-col>
              <v-col
                cols="12"
                md="12"
                class="pb-12"
              >
                <div
                  v-if="showFighterClaim"
                  class="card fighter"
                >
                  <div
                    class="ml-7 mt-3 v-card v-sheet v-sheet--outlined theme--dark px-5  py-4 mr-8  glow-primary"
                    :class="{ 'glow': !coinClaimed, 'active': !coinClaimed }"
                  >
                    <h2
                      v-if="!fighterClaimed"
                      class="text-center"
                    >
                      Fighter Pack
                    </h2>
                    <div class="row">
                      <div class="col-md-12">
                        <v-img
                          v-if="!fighterClaimed"
                          :src="fighterImage"
                          alt="upgrade-banner"
                          transition="scale-transition"
                          class="img-responsive text-center mx-auto mt-4 mb-0 pb-0"
                          max-width="120"
                        ></v-img>
                      </div>
                      <div class="col-md-12">
                        <div class="animate">
                          <FighterCard
                            v-if="fighterClaimed"
                            :fighter="rankedFighter"
                          ></FighterCard>
                        </div>
                        <p
                          v-if="!fighterClaimed"
                          class="mt-3 text-center mb-1"
                        >
                          Receive 1 ranked fighter.
                        </p>

                        <div
                          v-if="fighterClaiming"
                          class="text-center text-xs col-12 mb-0 pb-0"
                        >
                          <span>Status: {{ NFTstatus }}</span>
                        </div>

                        <p
                          v-if="fighterClaimed"
                          class="mt-10 text-center mb-0"
                        >
                          <v-icon
                            color="success"
                          >
                            {{ icons.mdiCheckCircleOutline }}
                          </v-icon>
                          Successfully recruited <span
                          class="font-weight-bold"
                        >{{ rankedFighter.name }}</span> to
                          your team!
                        </p>
                        <v-flex
                          class="text-xs-center align-center"
                          :class="{ 'ml-11': !fighterClaimed }"
                        >
                          <v-btn
                            :loading="fighterClaiming"
                            :disabled="fighterClaimed"
                            color="primary"
                            class="mt-7 ml-4 v-btn v-btn--is-elevated v-btn--has-bg theme--dark v-size--default primary"
                            :class="{ 'ml-11 ': fighterClaimed }"
                            @click="fighterClaim()"
                          >
                            <v-icon
                              v-if="fighterClaimed"
                              color="success"
                              class="mr-2"
                            >
                              {{ icons.mdiCheckCircleOutline }}
                            </v-icon>
                            {{ fighterClaimText }}
                          </v-btn>
                          <v-btn
                            v-if="fighterClaimed"
                            class="ml-2 mt-7"
                            color="primary"
                            @click="hideFighter"
                          >
                            Next
                          </v-btn>
                        </v-flex>
                      </div>
                    </div>
                  </div>
                </div>
              </v-col>
              <v-col
                cols="12"
                md="12"
                class="pb-12"
              >
                <div
                  v-if="showCoinClaim"
                  class="card coins"
                >
                  <div
                    class="ml-7 mt-3 v-card v-sheet v-sheet--outlined theme--dark px-5 py-4 mr-8 glow-warning"
                    :class="{ 'glow': !coinClaimed, 'active': !startClaim }"
                  >
                    <h2 class="text-center mb-3">
                      KombatCoins
                    </h2>
                    <div class="row">
                      <div class="col-md-12">
                        <v-img
                          :src="require(`@/assets/images/avatars/KombatCoinMain.png`)"
                          alt="KombatCoins"
                          transition="scale-transition"
                          class="img-responsive text-center mx-auto img-rounded"
                          max-width="150"
                          contain
                        ></v-img>
                      </div>
                    </div>
                    <p class="mt-2 pt-1 text-center">
                      Use KombatCoins to recruit and trade fighters!
                    </p>
                    <div
                      class="col-8 offset-2"
                      :class="{ 'opacity-0': coinClaimed }"
                    >
                      <div class="row">
                        <div
                          class="col-md-2 col-xs-2 col-sm-2 offset-sm-2 offset-md-1 col-2 offset-1"
                        >
                          <v-img
                            :src="require(`@/assets/images/buy/KombatCoinSilver.png`)"
                            alt="upgrade-banner"
                            transition="scale-transition"
                            class="float-left mb-2 pt-0 mr-5"
                            :class="{ 'cardSpinner': coinClaiming }"
                            max-width="35"
                          ></v-img>
                        </div>
                        <div class="col-md-9 col-xs-9 col-sm-9 col-9">
                          <span
                            id="countDown"
                            class="text-2xl font-weight-semibold text-center ml-2"
                          >{{ amount|toCurrencyNoDec }}</span>
                        </div>
                        <div
                          v-if="coinClaiming"
                          class="text-center text-xs col-12"
                        >
                          Minting KombatCoins..
                        </div>
                      </div>
                    </div>
                    <v-btn
                      class="mt-1 ml-8 v-btn v-btn--is-elevated v-btn--has-bg theme--dark v-size--default warning"
                      :loading="coinClaiming"
                      :disabled="coinClaimed"
                      @click="firstClaim()"
                    >
                      <v-icon
                        v-if="coinClaimed"
                        color="success"
                        class="mr-1"
                      >
                        {{ icons.mdiCheckCircleOutline }}
                      </v-icon>
                      {{ coinClaimText }}
                    </v-btn>
                    <v-btn
                      v-if="coinClaimed"
                      class="ml-2 mt-1"
                      color="warning"
                      @click="hideCoins"
                    >
                      Next
                    </v-btn>
                  </div>
                </div>
              </v-col>
              <v-col
                cols="12"
                md="12"
                class="pb-12"
                :class="{ 'open-pack ': startClaim }"
              >
                <div class="card topcard plastic-wrap">
                  <div
                    v-if="!startClaim"
                    class="ribbon ribbon-top-right"
                  >
                    <span>Open here</span>
                  </div>
                </div>
              </v-col>
              <v-col
                cols="12"
                md="12"
                class="pb-12"
                :class="{ 'open-pack ': startClaim }"
              >
                <div class="card topcard cover">
                  <h1 class="text-center">
                    FantasyKombat
                    <br>
                    Starter pack
                  </h1>
                </div>
              </v-col>
            </v-row>
          </div>
        </div>
      </v-col>
    </v-row>
    <audio
      ref="audio"
      :src="audioWrapper.src"
      id="caudio"
    ></audio>
  </div>
</template>

<script>
import {mdiCheckCircleOutline} from '@mdi/js'
import firebase from 'firebase/app'
import 'firebase/functions'
import dbFunctions from '@/db'
import tokenFunctions from '@/tokens'
import FighterCard from '@/views/components/FighterCard.vue'

const silverKombatCoinTemplate = process.env.VUE_APP_SILVER_KOMBATCOIN_TEMPLATE

export default {
  components: {
    FighterCard,
  },
  setup() {
    return {
      icons: {
        mdiCheckCircleOutline,
      },
    }
  },
  data() {
    return {
      isMobile: false,
      user: '',
      NFTstatus: '',
      startClaim: false,
      coinClaimed: true,
      fighterClaimed: false,
      coinClaiming: false,
      coinMinted: false,
      showCoinClaim: true,
      showFighterClaim: true,
      fighterClaiming: false,
      coinClaimText: 'Claim your KombatCoins',
      fighterClaimText: 'Claim Fighter Pack',
      amount: 2000000,
      fighterImage: require('@/assets/images/avatars/FantasyFighterCard.png'),
      rankedFighter: {},
      audioWrapper: {
        src: require('@/assets/sounds/Quest_Game_UI_Bass_Tap_Button_Click_1_Snap.wav'),
      },
    }
  },
  beforeDestroy() {
    if (typeof window === 'undefined') return
    window.removeEventListener('resize', this.onResize, {passive: true})
  },
  mounted() {
    this.getUser()
    this.onResize()
    this.audioWrapper = document.getElementById('caudio')
  },
  methods: {
    onResize() {
      this.isMobile = window.innerWidth < 600
    },
    hideCoins() {
      this.showCoinClaim = false
    },
    hideFighter() {
      this.showFighterClaim = false
    },
    openPack() {
      this.startClaim = true
    },
    async startTokenMint() {
      let playerId = ''
      this.NFTstatus = 'Checking player wallet Id'
      if (this.user.profile.starDustPlayerId) {
        playerId = this.user.profile.starDustPlayerId
        this.mintCoins(playerId)
      } else {
        const getPlayerId = firebase.functions().httpsCallable('getPlayerId')
        getPlayerId({uid: this.user.uid})
          .then(data => {
            dbFunctions.updateUsersProfile(this.user, {
              starDustPlayerId: data.data.playerId,
            })
              .then(() => {
                this.NFTstatus = 'Wallet Id Check OK'
                playerId = data.data.playerId
                this.user.profile.starDustPlayerId = playerId
                this.mintCoins(playerId)

                return playerId
              })
              .catch(err => {
                console.log(err)
              })
          })
          .catch(err => {
            console.log(err)
          })
      }

      return playerId
    },
    firstClaim() {
      this.coinClaiming = true
      let amount = this.amount // eslint-disable-line
      let myCoins = 0
      this.audioWrapper.src = require('@/assets/sounds/Bonus Coin Success.mp3')
      this.audioWrapper.currentTime = 0
      this.audioWrapper.volume = 0.1
      this.audioWrapper.play()
      this.startTokenMint()
      const counter = setInterval(() => {
        if (amount <= 0) {
          clearInterval(counter)
        }
        if (document.getElementById('countDown')) {
          document.getElementById('countDown').innerText = amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
          amount -= 2000
        }
      }, 1)
      const counter2 = setInterval(() => {
        if (myCoins >= this.amount) {
          if (this.coinMinted) {
            this.coinClaiming = false
            this.coinClaimed = true
            this.coinClaimText = ' Claimed'
          }
          clearInterval(counter2)
        }
        myCoins += 2000
      }, 1)
    },
    async getFighter(fighterId) {
      dbFunctions.getBaseFighter(fighterId)
        .then(fighterData => {
          this.rankedFighter = fighterData
          this.getFighterTokenTemplate()
        })
        .catch(err => {
          console.log(err)
        })
    },
    async fighterClaim() {
      this.fighterClaiming = true
      dbFunctions.createTeam(this.user.uid)
      this.NFTstatus = 'Spinning the random wheel' // Chainlink Randomness https://docs.chain.link/docs/chainlink-vrf/
      console.log('Get ranked fighter')
      await dbFunctions.getRandomRankedFighter()
        .then(fighterId => {
          this.getFighter(fighterId)

          return fighterId
        })
        .catch(err => {
          console.log(err)
        })
    },
    async getFighterTokenTemplate() {
      if (this.rankedFighter.token_template_id) {
        this.mintFighter(this.rankedFighter.token_template_id)
      } else {
        this.rankedFighter.description = `UFC Fighter ${this.rankedFighter.name} base card`
        const templateData = {
          name: this.rankedFighter.name,
          cap: this.rankedFighter.cap,
          type: 'NFT',
          props: this.rankedFighter,
          slug: this.rankedFighter.slug,
          card_type: this.rankedFighter.card_type || 'base',
        }
        await tokenFunctions.createTokenTemplate(templateData)
          .then(templateId => {
            this.rankedFighter.token_template_id = templateId
            this.mintFighter(templateId)
          }).catch(err => {
            console.log(err)
          })
      }
    },
    async mintCoins(playerId) {
      console.log('Start coin mint')
      this.coinMinted = tokenFunctions.mintToken(playerId, silverKombatCoinTemplate, this.amount, this.user)
      dbFunctions.updateUsersProfile(this.user, {
        onBoardered: true,
        freeSilverKombatCoins: firebase.firestore.FieldValue.increment(this.amount),
        silverKombatCoinsLastTrans: this.amount,
        silverKombatCoins: firebase.firestore.FieldValue.increment(this.amount),
      })
        .then(() => {
          console.log('Minted coins saved')
          this.coinClaiming = false
          this.coinClaimed = true
          this.coinClaimText = ' Claimed'
          this.$store.dispatch('getUserProfile', this.user)
        })
        .catch(err => {
          console.log(err)
        })
    },
    async mintFighter(templateId) {
      console.log('Start NFT Mint')
      this.NFTstatus = 'Contract negotiation started'
      await tokenFunctions.mintToken(this.user.profile.starDustPlayerId, templateId, 1, this.user)
        .then(mintedTokenId => {
          this.rankedFighter.mintedTokenId = mintedTokenId[0] // eslint-disable-line
          dbFunctions.updateUsersProfile(this.user, {
            freeFighterPacks: firebase.firestore.FieldValue.increment(1),
          })
            .then(() => {
              this.NFTstatus = 'Success'
              this.fighterClaimText = ' Claimed'
              this.fighterClaimed = true
              this.fighterClaiming = false
              dbFunctions.saveMintedFighter(this.rankedFighter, this.user.uid)
              this.audioWrapper.src = require('@/assets/sounds/Pro Magic Reveal.wav')
              this.audioWrapper.currentTime = 0
              this.audioWrapper.volume = 0.1
              this.audioWrapper.play()
            })
            .catch(err => {
              console.log(err)
            })
        })
        .catch(err => {
          console.log(err)
        })
    },
    getUser() {
      this.user = this.$store.state.user
      if (this.user) {
        dbFunctions.readUsersProfile(this.user.uid)
          .then(profileData => {
            this.$store.state.user.profile = profileData
            this.user = this.$store.state.user
            localStorage.setItem('user', JSON.stringify(this.user))

            this.coinClaimed = this.$store.state.user.profile.onBoardered
            if (this.coinClaimed) {
              this.coinClaimText = ' Claimed'
            }
            if (this.$store.state.user.profile.freeFighterPacks > 0) {
              this.fighterClaimed = true
              this.showFighterClaim = false
              this.fighterClaimText = ' Claimed'
            }
          })
      } else {
        this.$router.push({path: 'login'})
      }
    },
  },
}
</script>

<style>

.infoCard.slideAnimate {
  opacity: 1;
  width: 342px;
  height: 279px;
  left: 276px;
  text-align: left;
}

html {
  background-color: #28243d;
}

.opacity-1 {
  opacity: 1;
  color: #fff;
}

.mobile .cards-wrapper {
  transform: scale(0.55);
  transform-origin: center;
  margin-right: 32%;
}

.mobile .cards-box {
  top: -70px;
}

.cards-box {
  position: relative;
  margin: auto;
  width: 448px;
}

.cards-box .pb-12 {
  position: absolute;
}

.card .v-card {
  min-width: 400px;
  min-height: 476px;
  padding: 40px !important;
}

.card.cover {
  text-align: center;
  font-family: 'Black Ops One';
  font-size: 20px;
  vertical-align: middle;
  margin-top: 200px;
  margin-left: 19px;
  text-shadow: 1px 1px 2px black;
  color: #ddd;
  z-index: 9999;
  cursor: grab;

}

.card.plastic-wrap {
  height: 570px;
  opacity: 0.9 !important;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: saturate(117%) blur(12px);
  border: 2px solid #fff;
  width: 445px;
  border-radius: 8px;
  cursor: grab;
}

.cards-wrapper.closed {
  cursor: grab;
}

.card:hover {
  transform: rotate(
    0deg) !important;
  transition: transform 150ms ease-in-out 250ms;
}

.card.team .v-card.active {
  transform: rotate(
    -2deg);
}

.card.fighter .v-card.active {
  transform: rotate(
    2deg);
}

.card.coins .v-card.active {
  transform: rotate(
    -1deg);
}

.ribbon {
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: absolute;
  cursor: grab;
}

.ribbon::before,
.ribbon::after {
  position: absolute;
  z-index: -1;
  content: '';
  display: block;
  border: 5px solid #2980b9;
}

.ribbon span {
  position: absolute;
  display: block;
  width: 225px;
  padding: 15px 0;
  background-color: #3498db;
  box-shadow: 0 5px 10px rgba(0, 0, 0, .1);
  color: #fff;
  font: 700 16px/1 Helvetica, Arial, sans-serif;
  text-shadow: 0 1px 1px rgba(0, 0, 0, .2);
  text-transform: uppercase;
  text-align: center;
}

/* top right*/
.ribbon-top-right {
  position: relative;
  z-index: 999;
  float: right;
  text-align: right;
  margin: -12px;
  cursor: grab;
}

.ribbon-top-right::before,
.ribbon-top-right::after {
  border-top-color: transparent;
  border-right-color: transparent;
}

.ribbon-top-right::before {
  top: 0;
  left: 0;
}

.ribbon-top-right::after {
  bottom: 0;
  right: 0;
}

.ribbon-top-right span {
  left: -25px;
  top: 30px;
  transform: rotate(45deg);
  box-shadow: 2px 2px 11px #333;
}

.opened .open-pack {
  animation: anim 1s linear forwards;
}

@keyframes anim {
  10% {
    border: none;
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  50% {
    border: none;
    opacity: 0;
    display: none !important;
    transform: translate3d(0, 50%, 0);
    overflow: hidden;

  }
  100% {
    border: none;
    opacity: 0;
    display: none !important;
    transform: translate3d(0, 140%, 0);
    visibility: hidden;
    height: 0;

  }
}

.cards-box .fighterCardWrapper {
  transform: scale(0.7);
  margin-top: -90px;
  margin-bottom: -80px;
  transition: transform 0.6s;
}

.cards-box .fighterCardWrapper:hover {
  transform: scale(1.1) !important;
  position: relative;
  z-index: 99999;
}

.cards-box .v-card.v-sheet.v-sheet--outlined.theme--dark {
  background: #312d4b !important;
}
</style>
